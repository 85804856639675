import { render, staticRenderFns } from "./InvestmentAdviceView.vue?vue&type=template&id=2544054a&"
import script from "./InvestmentAdviceView.vue?vue&type=script&lang=js&"
export * from "./InvestmentAdviceView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2544054a')) {
      api.createRecord('2544054a', component.options)
    } else {
      api.reload('2544054a', component.options)
    }
    module.hot.accept("./InvestmentAdviceView.vue?vue&type=template&id=2544054a&", function () {
      api.rerender('2544054a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/investmentAdvice/InvestmentAdviceView.vue"
export default component.exports