import { render, staticRenderFns } from "./KundenzugangUndKundenApp.vue?vue&type=template&id=28dfe806&scoped=true&"
import script from "./KundenzugangUndKundenApp.vue?vue&type=script&lang=js&"
export * from "./KundenzugangUndKundenApp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28dfe806",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('28dfe806')) {
      api.createRecord('28dfe806', component.options)
    } else {
      api.reload('28dfe806', component.options)
    }
    module.hot.accept("./KundenzugangUndKundenApp.vue?vue&type=template&id=28dfe806&scoped=true&", function () {
      api.rerender('28dfe806', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/core/kundenzugang-und-kundenapp/KundenzugangUndKundenApp.vue"
export default component.exports