import { render, staticRenderFns } from "./Einzelnfonds.vue?vue&type=template&id=28abd591&scoped=true&"
import script from "./Einzelnfonds.vue?vue&type=script&lang=js&"
export * from "./Einzelnfonds.vue?vue&type=script&lang=js&"
import style0 from "./Einzelnfonds.vue?vue&type=style&index=0&id=28abd591&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28abd591",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('28abd591')) {
      api.createRecord('28abd591', component.options)
    } else {
      api.reload('28abd591', component.options)
    }
    module.hot.accept("./Einzelnfonds.vue?vue&type=template&id=28abd591&scoped=true&", function () {
      api.rerender('28abd591', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/cms/template5/Einzelnfonds.vue"
export default component.exports