import { render, staticRenderFns } from "./AbschlussPortale.vue?vue&type=template&id=775b715c&scoped=true&"
import script from "./AbschlussPortale.vue?vue&type=script&lang=js&"
export * from "./AbschlussPortale.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "775b715c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('775b715c')) {
      api.createRecord('775b715c', component.options)
    } else {
      api.reload('775b715c', component.options)
    }
    module.hot.accept("./AbschlussPortale.vue?vue&type=template&id=775b715c&scoped=true&", function () {
      api.rerender('775b715c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/beratung/AbschlussPortale.vue"
export default component.exports