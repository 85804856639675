import { render, staticRenderFns } from "./HomeTemplateA.vue?vue&type=template&id=881dba50&scoped=true&"
import script from "./HomeTemplateA.vue?vue&type=script&lang=js&"
export * from "./HomeTemplateA.vue?vue&type=script&lang=js&"
import style0 from "./HomeTemplateA.vue?vue&type=style&index=0&id=881dba50&scoped=true&lang=css&"
import style1 from "./HomeTemplateA.vue?vue&type=style&index=1&id=881dba50&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "881dba50",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('881dba50')) {
      api.createRecord('881dba50', component.options)
    } else {
      api.reload('881dba50', component.options)
    }
    module.hot.accept("./HomeTemplateA.vue?vue&type=template&id=881dba50&scoped=true&", function () {
      api.rerender('881dba50', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/cms/HomeTemplateA.vue"
export default component.exports