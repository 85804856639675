import { render, staticRenderFns } from "./InputCheckBoxItem.vue?vue&type=template&id=05056978&scoped=true&"
import script from "./InputCheckBoxItem.vue?vue&type=script&lang=js&"
export * from "./InputCheckBoxItem.vue?vue&type=script&lang=js&"
import style0 from "./InputCheckBoxItem.vue?vue&type=style&index=0&id=05056978&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05056978",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05056978')) {
      api.createRecord('05056978', component.options)
    } else {
      api.reload('05056978', component.options)
    }
    module.hot.accept("./InputCheckBoxItem.vue?vue&type=template&id=05056978&scoped=true&", function () {
      api.rerender('05056978', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/forms/checkbox/InputCheckBoxItem.vue"
export default component.exports