import { render, staticRenderFns } from "./Aenderungslog.vue?vue&type=template&id=56b19595&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56b19595')) {
      api.createRecord('56b19595', component.options)
    } else {
      api.reload('56b19595', component.options)
    }
    module.hot.accept("./Aenderungslog.vue?vue&type=template&id=56b19595&", function () {
      api.rerender('56b19595', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/otherProducts/steps/Aenderungslog.vue"
export default component.exports