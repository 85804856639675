import { render, staticRenderFns } from "./InviteToSsoModal.vue?vue&type=template&id=1fd27d12&scoped=true&"
import script from "./InviteToSsoModal.vue?vue&type=script&lang=js&"
export * from "./InviteToSsoModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fd27d12",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1fd27d12')) {
      api.createRecord('1fd27d12', component.options)
    } else {
      api.reload('1fd27d12', component.options)
    }
    module.hot.accept("./InviteToSsoModal.vue?vue&type=template&id=1fd27d12&scoped=true&", function () {
      api.rerender('1fd27d12', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/sso/InviteToSsoModal.vue"
export default component.exports