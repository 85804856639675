import { render, staticRenderFns } from "./VVArchivVariante.vue?vue&type=template&id=ad8b1668&"
import script from "./VVArchivVariante.vue?vue&type=script&lang=js&"
export * from "./VVArchivVariante.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ad8b1668')) {
      api.createRecord('ad8b1668', component.options)
    } else {
      api.reload('ad8b1668', component.options)
    }
    module.hot.accept("./VVArchivVariante.vue?vue&type=template&id=ad8b1668&", function () {
      api.rerender('ad8b1668', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/vermoegensverwaltung/VVArchivVariante.vue"
export default component.exports