import { render, staticRenderFns } from "./EditSocialMediaSrc.vue?vue&type=template&id=15ba5ccd&scoped=true&"
import script from "./EditSocialMediaSrc.vue?vue&type=script&lang=js&"
export * from "./EditSocialMediaSrc.vue?vue&type=script&lang=js&"
import style0 from "./EditSocialMediaSrc.vue?vue&type=style&index=0&id=15ba5ccd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15ba5ccd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15ba5ccd')) {
      api.createRecord('15ba5ccd', component.options)
    } else {
      api.reload('15ba5ccd', component.options)
    }
    module.hot.accept("./EditSocialMediaSrc.vue?vue&type=template&id=15ba5ccd&scoped=true&", function () {
      api.rerender('15ba5ccd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/cms/EditSocialMediaSrc.vue"
export default component.exports