import { render, staticRenderFns } from "./BeratungsmappenMail.vue?vue&type=template&id=940bda86&scoped=true&"
import script from "./BeratungsmappenMail.vue?vue&type=script&lang=js&"
export * from "./BeratungsmappenMail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "940bda86",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('940bda86')) {
      api.createRecord('940bda86', component.options)
    } else {
      api.reload('940bda86', component.options)
    }
    module.hot.accept("./BeratungsmappenMail.vue?vue&type=template&id=940bda86&scoped=true&", function () {
      api.rerender('940bda86', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/mailcomposer/types/BeratungsmappenMail.vue"
export default component.exports