import { render, staticRenderFns } from "./OptionsMenuApplyConfigModal.vue?vue&type=template&id=63763c09&scoped=true&"
import script from "./OptionsMenuApplyConfigModal.vue?vue&type=script&lang=js&"
export * from "./OptionsMenuApplyConfigModal.vue?vue&type=script&lang=js&"
import style0 from "./OptionsMenuApplyConfigModal.vue?vue&type=style&index=0&id=63763c09&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63763c09",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63763c09')) {
      api.createRecord('63763c09', component.options)
    } else {
      api.reload('63763c09', component.options)
    }
    module.hot.accept("./OptionsMenuApplyConfigModal.vue?vue&type=template&id=63763c09&scoped=true&", function () {
      api.rerender('63763c09', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/option-menu/optionMenuConfig/OptionsMenuApplyConfigModal.vue"
export default component.exports