import { render, staticRenderFns } from "./EMailAccessOrdnerstruktur.vue?vue&type=template&id=4660479c&scoped=true&"
import script from "./EMailAccessOrdnerstruktur.vue?vue&type=script&lang=js&"
export * from "./EMailAccessOrdnerstruktur.vue?vue&type=script&lang=js&"
import style0 from "./EMailAccessOrdnerstruktur.vue?vue&type=style&index=0&id=4660479c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4660479c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4660479c')) {
      api.createRecord('4660479c', component.options)
    } else {
      api.reload('4660479c', component.options)
    }
    module.hot.accept("./EMailAccessOrdnerstruktur.vue?vue&type=template&id=4660479c&scoped=true&", function () {
      api.rerender('4660479c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/intern/emailAccess/EMailAccessOrdnerstruktur.vue"
export default component.exports