import { render, staticRenderFns } from "./ComdirectDepotUebertragungDepotdaten.vue?vue&type=template&id=57d3155e&scoped=true&"
import script from "./ComdirectDepotUebertragungDepotdaten.vue?vue&type=script&lang=js&"
export * from "./ComdirectDepotUebertragungDepotdaten.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57d3155e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57d3155e')) {
      api.createRecord('57d3155e', component.options)
    } else {
      api.reload('57d3155e', component.options)
    }
    module.hot.accept("./ComdirectDepotUebertragungDepotdaten.vue?vue&type=template&id=57d3155e&scoped=true&", function () {
      api.rerender('57d3155e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/antrag/uebertragungsauftrag/ComdirectDepotUebertragungDepotdaten.vue"
export default component.exports