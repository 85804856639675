import { render, staticRenderFns } from "./DCProduct.vue?vue&type=template&id=569d8118&"
import script from "./DCProduct.vue?vue&type=script&lang=js&"
export * from "./DCProduct.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('569d8118')) {
      api.createRecord('569d8118', component.options)
    } else {
      api.reload('569d8118', component.options)
    }
    module.hot.accept("./DCProduct.vue?vue&type=template&id=569d8118&", function () {
      api.rerender('569d8118', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/externeZugang/DCProduct.vue"
export default component.exports