import { render, staticRenderFns } from "./DepotnummerAnfordernButton.vue?vue&type=template&id=732c8265&scoped=true&"
import script from "./DepotnummerAnfordernButton.vue?vue&type=script&lang=js&"
export * from "./DepotnummerAnfordernButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "732c8265",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('732c8265')) {
      api.createRecord('732c8265', component.options)
    } else {
      api.reload('732c8265', component.options)
    }
    module.hot.accept("./DepotnummerAnfordernButton.vue?vue&type=template&id=732c8265&scoped=true&", function () {
      api.rerender('732c8265', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/forms/antraege/DepotnummerAnfordernButton.vue"
export default component.exports