import { render, staticRenderFns } from "./UndrawNewspaper.vue?vue&type=template&id=98c1ffe2&"
import script from "./UndrawNewspaper.vue?vue&type=script&lang=js&"
export * from "./UndrawNewspaper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('98c1ffe2')) {
      api.createRecord('98c1ffe2', component.options)
    } else {
      api.reload('98c1ffe2', component.options)
    }
    module.hot.accept("./UndrawNewspaper.vue?vue&type=template&id=98c1ffe2&", function () {
      api.rerender('98c1ffe2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/icons/undraw/UndrawNewspaper.vue"
export default component.exports