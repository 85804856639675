import { render, staticRenderFns } from "./CustomerOverview.vue?vue&type=template&id=0df828b8&scoped=true&"
import script from "./CustomerOverview.vue?vue&type=script&lang=js&"
export * from "./CustomerOverview.vue?vue&type=script&lang=js&"
import style0 from "../steckbrief.scss?vue&type=style&index=0&id=0df828b8&lang=scss&scoped=true&"
import style1 from "./CustomerOverview.vue?vue&type=style&index=1&id=0df828b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0df828b8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0df828b8')) {
      api.createRecord('0df828b8', component.options)
    } else {
      api.reload('0df828b8', component.options)
    }
    module.hot.accept("./CustomerOverview.vue?vue&type=template&id=0df828b8&scoped=true&", function () {
      api.rerender('0df828b8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/steckbrief/cards/CustomerOverview.vue"
export default component.exports