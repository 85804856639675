import { render, staticRenderFns } from "./KennzahlenTableScnd.vue?vue&type=template&id=31a52dc2&"
import script from "./KennzahlenTableScnd.vue?vue&type=script&lang=js&"
export * from "./KennzahlenTableScnd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31a52dc2')) {
      api.createRecord('31a52dc2', component.options)
    } else {
      api.reload('31a52dc2', component.options)
    }
    module.hot.accept("./KennzahlenTableScnd.vue?vue&type=template&id=31a52dc2&", function () {
      api.rerender('31a52dc2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/vermogen/kennzahlen/KennzahlenTableScnd.vue"
export default component.exports