import { render, staticRenderFns } from "./OptionsMenuApplyButton.vue?vue&type=template&id=2058fab8&"
import script from "./OptionsMenuApplyButton.vue?vue&type=script&lang=js&"
export * from "./OptionsMenuApplyButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2058fab8')) {
      api.createRecord('2058fab8', component.options)
    } else {
      api.reload('2058fab8', component.options)
    }
    module.hot.accept("./OptionsMenuApplyButton.vue?vue&type=template&id=2058fab8&", function () {
      api.rerender('2058fab8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/option-menu/optionMenuConfig/OptionsMenuApplyButton.vue"
export default component.exports