import { render, staticRenderFns } from "./HomeTemplate8.vue?vue&type=template&id=891b6162&scoped=true&"
import script from "./HomeTemplate8.vue?vue&type=script&lang=js&"
export * from "./HomeTemplate8.vue?vue&type=script&lang=js&"
import style0 from "./HomeTemplate8.vue?vue&type=style&index=0&id=891b6162&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "891b6162",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('891b6162')) {
      api.createRecord('891b6162', component.options)
    } else {
      api.reload('891b6162', component.options)
    }
    module.hot.accept("./HomeTemplate8.vue?vue&type=template&id=891b6162&scoped=true&", function () {
      api.rerender('891b6162', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/cms/HomeTemplate8.vue"
export default component.exports