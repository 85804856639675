import { render, staticRenderFns } from "./SetupTapiDialog.vue?vue&type=template&id=60297c72&scoped=true&"
import script from "./SetupTapiDialog.vue?vue&type=script&lang=js&"
export * from "./SetupTapiDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60297c72",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60297c72')) {
      api.createRecord('60297c72', component.options)
    } else {
      api.reload('60297c72', component.options)
    }
    module.hot.accept("./SetupTapiDialog.vue?vue&type=template&id=60297c72&scoped=true&", function () {
      api.rerender('60297c72', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/tapi/SetupTapiDialog.vue"
export default component.exports