import { render, staticRenderFns } from "./DynamicDocumentList.vue?vue&type=template&id=188e7bd2&scoped=true&"
import script from "./DynamicDocumentList.vue?vue&type=script&lang=js&"
export * from "./DynamicDocumentList.vue?vue&type=script&lang=js&"
import style0 from "./DynamicDocumentList.vue?vue&type=style&index=0&id=188e7bd2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "188e7bd2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('188e7bd2')) {
      api.createRecord('188e7bd2', component.options)
    } else {
      api.reload('188e7bd2', component.options)
    }
    module.hot.accept("./DynamicDocumentList.vue?vue&type=template&id=188e7bd2&scoped=true&", function () {
      api.rerender('188e7bd2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dynamicDocumentList/DynamicDocumentList.vue"
export default component.exports