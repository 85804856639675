import { render, staticRenderFns } from "./OptionsMenuConfigViewDiffHelpDropdown.vue?vue&type=template&id=71d3ec8a&scoped=true&"
import script from "./OptionsMenuConfigViewDiffHelpDropdown.vue?vue&type=script&lang=js&"
export * from "./OptionsMenuConfigViewDiffHelpDropdown.vue?vue&type=script&lang=js&"
import style0 from "./OptionsMenuConfigViewDiffHelpDropdown.vue?vue&type=style&index=0&id=71d3ec8a&lang=scss&scoped=true&"
import style1 from "./OptionsMenuConfigViewDiffHelpDropdown.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71d3ec8a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71d3ec8a')) {
      api.createRecord('71d3ec8a', component.options)
    } else {
      api.reload('71d3ec8a', component.options)
    }
    module.hot.accept("./OptionsMenuConfigViewDiffHelpDropdown.vue?vue&type=template&id=71d3ec8a&scoped=true&", function () {
      api.rerender('71d3ec8a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/option-menu/optionMenuConfig/OptionsMenuConfigViewDiffHelpDropdown.vue"
export default component.exports